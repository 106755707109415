.big-container {
  display: flex;
  flex-direction: column;
  padding: 0 6rem 0 6rem;
  max-width: 100%;
  background-color: #F3E5E5;
  align-items: center;
  align-self: center;
  flex-grow: 1;
  min-height: calc(100vh - 2.4rem);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(80,0,0,0.6), rgba(80,0,0,0.7)), url("./background.jpg");
  overflow: visible;
}

.root {
  overflow: visible;
}
