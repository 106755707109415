.chart-toolbar {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
}

.button-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chart-view {
  margin-bottom: 1.5rem;
}