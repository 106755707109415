.legend-color {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  display: flex;
  margin-right: 0.5rem;
  border: 1px solid rgb(169, 169, 169);
}

.chart-legend {
  /* padding: 1rem; */
  background-color: grey;
  flex: 2;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.legend-row {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.legend-title {
  margin: 1rem 0 1rem 0.5rem;
}