@import url("https://fonts.googleapis.com/css?family=Overpass+Mono&display=swap");

.chart-container {
  font-family: 'Overpass Mono', monospace;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  font-size: 1rem;
  background-color: lightgrey;
}

.chart-row {
  display: flex;
  flex-direction: row;
}

@media (max-width: 540px) {
  .chart-container {
    font-size: calc((100vw - 1rem) / 16 * 0.55)
  }
}