@import url("./normalize.css");
@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

html, * {
  box-sizing: border-box;
}

@media (max-width: 390px) {
  body {
    max-width: 100vw;
  }
}

