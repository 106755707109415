.chart-btn {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #47817E;
  color: rgba(255, 255, 255, 0.9);
  opacity: 0.8;
  min-width: 8rem;
}

.del-btn {
  background-color: rgb(175, 40, 40);
}

.chart-btn:hover {
  opacity: 1;
}