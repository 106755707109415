.chart-name-input {
  flex: 1;
  padding: 0.6rem;
  border: 1px solid rgb(169, 169, 169);
  width: 100%;
  font-size: 100%;
}

.chart-name-label {
  font-weight: bold;
  padding: 0.5rem 0 0.5rem 0;
}

.submit-chart-btn {
  background-color: #59BAB7;
}

.cancel-form-btn {
  background-color: #DA4539;
}

.chart-name-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}