.range-form {
  background-color: grey;
  display: flex;
  flex-direction: column;
  flex: 2;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.range-form-title {
  margin: 1rem 0 1rem 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.range-row {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.range-row.selected {
  background-color: #863271;
}

.range-name-row-input {
  background-color: inherit;
  border: none;
  padding: 0.2rem;
  color: rgba(255, 255, 255, 0.8);
  flex: 1;
  margin-right: 0.5rem;
}

.range-name-row-input:placeholder-shown {
  color: rgba(255, 255, 255, 0.6)
}

input.range-name-row-input, [contenteditable] {
  caret-color: white;
}

.color-select {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.material-icons.del-range-btn {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.material-icons.del-range-btn:hover {
  color: #DA4539;
}
 
select.color-select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select.color-select::-ms-expand {
  display: none;
}

.range-row:last-child.selected {
  border-radius: 0 0 0.5rem 0.5rem;
}