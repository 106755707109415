.landing-container {
  width: 100%;
  background-color: #F3E5E5;
  min-height: calc(100vh - 2.4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.tutorial-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-area {
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0 0.5rem 0 0.5rem;
}

.section-title {
  padding-top: 2rem;
  font-size: 1.8rem;
  text-align: center;
}

.section-para {
  line-height: 1.4;
}

.section-img {
  max-width: 50%;
  align-self: center;
  padding: 1rem;
  background-color: lightsteelblue;
  -webkit-box-shadow: 5px 5px 5px #666;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    5px 5px 5px #666;  /* Firefox 3.5 - 3.6 */
  box-shadow:         5px 5px 5px #666;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.p-with-img {
  display: flex;
  flex-direction: row;
}

.p-with-img:last-of-type {
  padding-bottom: 3rem;
}

.p-with-img p {
  margin-top: 0;
  margin-right: 1rem;
}

.section-subtitle {
  padding-top: 4rem;
  align-self: center;
}

.note {
  padding: 1.5rem;
  background-color: darkgrey;
  border-radius: 1rem;
}

/* Hero Section Styles */

.hero {
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.7)), url('./hero.jpg');
  background-size: cover;
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.landing-title {
  font-size: 3rem;
  align-self: center;
  margin-bottom: 0;
  color: rgb(206, 49, 38);
  margin-bottom: 0.5rem;
}

.landing-subtitle {
  text-align: center;
  max-width: 25rem;
  margin: auto;
  font-size: 1.3rem;
  margin-bottom: 2.5rem;
  color: white;
}

.hero .section-para {
  color: white;
  padding: 1rem 1rem 2rem 1rem;
}

.cta-btn {
  background-color: rgba(89, 186, 183, 0.8);
  color: white;
  padding: 1rem;
  width: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  width: 20.2rem;
  align-self: center;
}

.cta-btn:hover {
  background-color: rgba(89, 186, 183, 1);
}